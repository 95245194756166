import { Component } from '@angular/core';
import { AuthService } from '@nimmsta/ng-commons-lib';

@Component({
  selector: 'app-waiting-list',
  templateUrl: './waiting-list.component.html',
  styleUrls: ['./waiting-list.component.scss'],
})
export class WaitingListComponent {
  constructor(private authService: AuthService) { }

  async logout() {
    await this.authService.logout(window.location.origin);
  }
}
