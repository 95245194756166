<div class="d-flex position-absolute h-100 w-100 reset-pwd-area">
  <div class="display-large">
    <div class="login-container">
      <img src="./assets/img/logo.png" class="logo" />
      <div class="mt-4">
        <h1 translate>verify-email.verify-email</h1>
        <app-error [error]="error"></app-error>
        @if (!verifiedSuccessfully && error === undefined) {
          <div>
            <button class="btn btn-primary w-100 mt-4 mb-5"
              (click)="verifyEmail()"
              [ladda]="isVerifyEmailLoading">
              <span translate>verify-email.verify-email</span>
            </button>
          </div>
        }
        @if (verifiedSuccessfully) {
          <div>
            <h3 translate>verify-email.success</h3>
            <p translate>verify-email.please_login_or_wait_for_apporval</p>
            <a href="/"
              class="btn btn-primary w-100 mt-4 mb-5" translate>
              login.login
            </a>
          </div>
        }
      </div>
    </div>
  </div>
  <div class="w-50 d-none d-md-block">
    <app-product-placement></app-product-placement>
  </div>
</div>
