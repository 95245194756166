<div class="title">
  <span class="count">{{sum}}&nbsp;</span>
  <span translate>{{title}}</span>
  <span translate>in_total</span>
</div>
<div class="item-holder">
  @for (item of data; track item) {
    @if (item.count > 0) {
      <div class="item" [style]="'width: ' + item.count/sum*100 + '%;'">
        <div class="bar" [class]="item.title.replaceAll(' ', '-').replaceAll('_', '-').toLowerCase()"></div>
      </div>
    }
  }
</div>
<div class="legend-holder">
  @for (item of data; track item) {
    @if (item.count > 0) {
      <div class="item">
        <div class="text-muted" translate>{{item.title}}</div>
        <div class="counter">
          <div [class]="item.title.replaceAll(' ', '-').replaceAll('_', '-').toLowerCase()"></div>
          <span>{{item.count}}</span>
        </div>
      </div>
    }
  }
</div>
<div class="icons"></div>
