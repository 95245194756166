<div class="d-flex position-absolute h-100 w-100 reset-pwd-area">
  <div class="display-large">
    <div class="login-container">
      <img src="./assets/img/logo.png" class="logo" />
      <form class="d-flex flex-column justify-content-center h-100"
        [formGroup]="resetForm" (ngSubmit)="onSubmit()">
        @if (!submitted) {
          <div>
            <h1 translate>password-reset.reset-your-password</h1>
            <p translate>password-reset.enter-password</p>
            <div class="mb-3" *ngIf="!code">
              <label for="reset_code" class="form-label" translate>password-reset.code</label>
              <input
                id="reset_code"
                class="form-control"
                type="text"
                formControlName="reset_code"
                placeholder="abcd1234"
                />
              <div class="textfield-hint ms-1">
                <div><small translate>password-reset.check-email</small></div>
              </div>
            </div>
            <div class="mb-3">
              <label for="password" class="form-label" translate>password-reset.new-password</label>
              <input
                id="password"
                class="form-control"
                type="password"
                formControlName="password"
                placeholder="********"
                />
              <div class="invalid-feedback">
                @if (resetForm.controls.password.errors?.['required']) {
                  <div translate>
                    error.required
                  </div>
                }
                @if (resetForm.controls.password.errors?.['minlength']) {
                  <div translate>
                    error.password.minlength
                  </div>
                }
                @if (resetForm.controls.password.errors?.['hasNumber']) {
                  <div translate>
                    error.password.hasNumber
                  </div>
                }
                @if (resetForm.controls.password.errors?.['hasCapitalCase']) {
                  <div translate>
                    error.password.hasUpperCase
                  </div>
                }
                @if (resetForm.controls.password.errors?.['hasSmallCase']) {
                  <div translate>
                    error.password.hasLowerCase
                  </div>
                }
                @if (resetForm.controls.password.errors?.['hasSpecialCharacters']) {
                  <div translate>
                    error.password.hasSpecialCharacter
                  </div>
                }
              </div>
            </div>
            <div class="mb-3">
              <label for="password_repeat" class="form-label" translate>password-reset.confirm-password</label>
              <input
                id="password_repeat"
                class="form-control"
                type="password"
                formControlName="password_repeat"
                placeholder="********"
                />
              <div class="invalid-feedback">
                @if (resetForm.controls.password_repeat.errors?.['required']) {
                  <div translate>
                    error.required
                  </div>
                }
                @if (resetForm.controls.password_repeat.errors?.['not_matching']) {
                  <div translate>
                    error.not_matching
                  </div>
                }
              </div>
            </div>
            <app-error [error]="error"></app-error>
            <button type="submit"
              class="btn btn-primary w-100 mt-4 mb-5"
              [class.disabled]="!resetForm.valid"
              [ladda]="isLoading">
              {{ 'password-reset.reset' | translate }}
            </button>
            <p>
              <span translate>password-reset.no-account</span>
              <a href="/register" translate>password-reset.signup</a>
              <span translate>password-reset.to-create</span>
            </p>
          </div>
        }
        @if (submitted) {
          <div>
            <h1 translate>password-reset.success</h1>
            <p translate>password-reset.login-new</p>
            <a href="/"
              class="btn btn-primary w-100 mt-4 mb-5" translate>
              password-reset.login
            </a>
          </div>
        }
        @if (!verifiedSuccessfully) {
          <div>
          <div style="width: 100%;
                      display: flex;
                      flex-direction: row;
                      justify-content: center;
                      align-items: center;">
              <div style="flex: 1"><mat-divider></mat-divider></div>
              <!-- TODO: Remove harcoded value for color -->
              <div class="text mat-typography px-2" style="color: #5d666f" translate>password-reset.no-receive</div>
              <div style="flex: 1"><mat-divider></mat-divider></div>
            </div>
            <button type="button"
              class="btn btn-outline-primary w-100 mt-4 mb-5"
              [ladda]="isResetPasswordLoading"
              (click)="onResendSubmit()">
              {{ 'password-reset.resend' | translate }}
            </button>
          </div>
        }
      </form>
    </div>
  </div>
  <div class="w-50 d-none d-md-block">
    <app-product-placement></app-product-placement>
  </div>
</div>
