import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { WorkflowStats, WorkflowStatus } from '@models/workflow_stats';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { DashboardService } from '@services/dashboard.service';
import { CardDataItem } from '@models/cardDataItem';
import dayjs from 'dayjs';
import { WorkflowService, Workflow } from '@nimmsta/ng-commons-lib';

_('dashboard.title');

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, OnDestroy {
  jobsPositive = true;

  hs50Data: CardDataItem[] = [new CardDataItem('Loading', 0)];

  today = dayjs();

  workerData: CardDataItem[] = [new CardDataItem('Loading', 0)];

  jobData: CardDataItem[] = [new CardDataItem('Loading', 0)];

  workflows: Workflow[] = [];

  workflowStats = [
    new WorkflowStats('Picking', WorkflowStatus.ACTIVE, 12, 20),
    new WorkflowStats('Picking2', WorkflowStatus.INACTIVE, 20, 20),
    new WorkflowStats('Picking3', WorkflowStatus.ACTIVE, 20, 12),
    new WorkflowStats('Picking', WorkflowStatus.ACTIVE, 12, 20),
    new WorkflowStats('Picking2', WorkflowStatus.ACTIVE, 20, 20),
    new WorkflowStats('Picking3', WorkflowStatus.ACTIVE, 20, 12),
    new WorkflowStats('Picking', WorkflowStatus.ACTIVE, 12, 20),
    new WorkflowStats('Picking2', WorkflowStatus.ACTIVE, 20, 20),
    new WorkflowStats('Picking3', WorkflowStatus.ACTIVE, 20, 12),
    new WorkflowStats('Picking', WorkflowStatus.ACTIVE, 12, 20),
    new WorkflowStats('Picking2', WorkflowStatus.ACTIVE, 20, 20),
    new WorkflowStats('Picking3', WorkflowStatus.ACTIVE, 20, 12),
    new WorkflowStats('Picking', WorkflowStatus.ACTIVE, 12, 20),
    new WorkflowStats('Picking2', WorkflowStatus.ACTIVE, 20, 20),
    new WorkflowStats('Picking3', WorkflowStatus.INACTIVE, 20, 12),
  ];

  isLoading = false;

  date = dayjs();

  private interval?: ReturnType<typeof setInterval> = undefined;

  constructor(
    private dashboardService: DashboardService,
    private workflowService: WorkflowService,
    private router: Router,
  ) {}

  ngOnInit() {
    this.isLoading = true;
    this.getData();
    this.interval = setInterval(() => this.getData(), 10000);
    this.workflowService.simpleList().subscribe((response) => {
      this.workflows = response;
      this.isLoading = false;
    });
  }

  ngOnDestroy() {
    clearInterval(this.interval);
  }

  private getData() {
    this.dashboardService.getOverviewData().subscribe((data) => {
      if (data.workerStatusCount) {
        this.workerData = data.workerStatusCount.map((item) => new CardDataItem(item.status, item.count));
      }
      if (data.jobsStatusCount) {
        this.jobData = data.jobsStatusCount.map((item) => new CardDataItem(item.status, item.count));
      }
      if (data.deviceStatusCount) {
        this.hs50Data = data.deviceStatusCount.map((item) => new CardDataItem(item.status, item.count));
      }
      this.date = dayjs();
    });
  }

  navigateTo(url: string) {
    this.router.navigate([url]);
  }

  getStatusString(status: WorkflowStatus): string {
    return WorkflowStatus[status].valueOf().toLowerCase();
  }
}
