<div class="d-flex position-absolute h-100 w-100 forgot-password-area">
  <div class="display-large">
    <div class="login-container">
      <img src="./assets/img/logo.png" class="logo" />
      <form class="d-flex flex-column justify-content-center h-100"
        [formGroup]="resetForm" (ngSubmit)="onSubmit()">
        <h1 translate>password-reset.reset-your-password</h1>
        @if (!submitted) {
          <div>
            <p translate>password-reset.forgot-password?</p>
            <div class="mb-3">
              <label for="email" class="form-label" translate>user.email</label>
              <input
                id="email"
                class="form-control"
                type="email"
                formControlName="email"
                placeholder="name@example.com"
                />
              <div class="invalid-feedback" translate>
                error.email
              </div>
            </div>
            <app-error [error]="error"></app-error>
            <button type="submit"
              class="btn btn-primary w-100 mt-4 mb-5"
              [class.disabled]="!resetForm.valid"
              [ladda]="isLoading">
              {{ 'password-reset.send-reset-link' | translate }}
            </button>
            <p>
              <span translate>password-reset.no-account</span>
              <a href="/register" translate>password-reset.signup</a>
              <span translate>password-reset.to-create</span>
            </p>
          </div>
        }
        @if (submitted) {
          <p translate>password-reset.email-sent</p>
          <a class="btn btn-primary w-100 mt-4 mb-5" href="/" translate>password-reset.back-to-login</a>
        }
      </form>
    </div>
  </div>
  <div class="w-50 d-none d-md-block">
    <app-product-placement></app-product-placement>
  </div>
</div>
