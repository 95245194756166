@if (success) {
  <div class="alert alert-success mt-3">
    <span class="material-icons-outlined me-2">error</span>
    <strong>Success:</strong>&nbsp;<span translate>support.email.send_success</span>
  </div>
} @else {
  <form [formGroup]="form" class="card">
    <div class="row">
      <div class="col-md-12 form-group">
        <label translate>support.email.sender</label>
        <app-input-with-error [control]="this.form.controls['mail']"></app-input-with-error>
      </div>
      <div class="col-md-12 form-group">
        <label translate>support.email.title</label>
        <app-input-with-error [control]="this.form.controls['title']"></app-input-with-error>
      </div>
      <div class="col-md-12 form-group mt-2">
        <label translate>support.email.message</label>
        <app-input-with-error [control]="this.form.controls['message']" inputType="textarea"></app-input-with-error>
      </div>
      <div class="col-md-12 form-group mt-2">
        <app-error [error]="error"></app-error>
      </div>
      <div class="mt-3">
        <button [disabled]="!form.valid" type="button" class="btn btn-primary w-100" (click)="save()" [ladda]="loading" translate="support.email.send"></button>
      </div>
    </div>
  </form>
}
