<div class="box">
  <div class="box-row header">
    <app-header-bar [title]="'support.title'"></app-header-bar>
  </div>
  <div class="box-row content mt-2">
    <div class="row w-100">
      <tabset #tabset>
        <tab heading="{{'support.docs' | translate}}" class="pt-4" (selectTab)="onSelectTab($event)">
          <div class="col-xl-4">
            <div class="card">
              <div class="card-body">
                <h3 class="card-title">
                  <span translate>support.doc1.title</span>
                </h3>
                <p translate>support.doc1.description</p>
                <a href="{{getDoc1Link()}}" target="_blank" class="btn btn-primary" translate>support.doc.button</a>
              </div>
            </div>
          </div>
        </tab>
        <!-- <tab heading="{{'support.ticket.name' | translate}}" class="pt-2" (selectTab)="onSelectTab($event)">
          <p translate>support.ticket.text</p>
          <app-ticket-form></app-ticket-form>
        </tab> -->
        <tab heading="{{'support.ticket.name' | translate}}" class="pt-2" (selectTab)="openOverview()"></tab>
        <tab heading="{{'support.email.name' | translate}}" class="pt-2" (selectTab)="onSelectTab($event)">
          <p translate>support.email.text</p>
          <app-email-form></app-email-form>
        </tab>
      </tabset>
    </div>
  </div>
</div>
