import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ContentsService, ContentItem, ContentType } from '@nimmsta/ng-b2b-lib';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-help',
  templateUrl: './help-item.component.html',
})
export class HelpItemComponent implements OnInit {
  id!: string;

  contentItem?: ContentItem;

  error?: HttpErrorResponse;

  constructor(private contentService: ContentsService, private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.id = params['id'];
      this.load();
    });
  }

  private load() {
    this.contentService.getForView(this.id).subscribe({
      next: (contentItem) => {
        this.contentItem = contentItem;
      },
      error: (error) => {
        this.error = error;
      },
    });
  }

  protected readonly ContentType = ContentType;
}
