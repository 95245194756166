import { Component, NgZone } from '@angular/core';
import {
  NavItem, UserRole, Version, VersionService,
} from '@nimmsta/ng-commons-lib';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { VERSION } from '@environments/version';
import { environment } from '@environments/environment';

_('dashboard.nav');
_('worker.nav');
_('dataconnection.nav');
_('dataconnection.template.nav');
_('workflow.nav');
_('workflow.default.nav');
_('user.nav');
_('device.nav');
_('software.nav');
_('support.nav');
_('job.nav');
_('admin.nav');

@Component({
  templateUrl: './layout.component.html',
})
export class LayoutComponent {
  protected _sidebarCollapsed = false;

  protected _mobileNavExpanded = false;

  frontendVersion?: Version = VERSION;

  backendVersion?: Version;

  constructor(private zone: NgZone, private versionService: VersionService) {
    this.versionService.setBaseURL(environment.apiUrls.workflow);
    this.versionService.getBackendVersion().subscribe((version) => {
      this.zone.run(() => {
        this.backendVersion = version;
      });
    });
  }

  adminItems = [
    new NavItem('workflow-templates', 'workflow.default.nav', 'star', [UserRole.ADMIN]),
    new NavItem('dataconnection-templates', 'dataconnection.template.nav', 'settings_input_component', [UserRole.ADMIN]),
    new NavItem('organizations', 'organizations.nav', 'groups', [UserRole.ADMIN]),
    new NavItem('admin-jobs', 'job.admin.nav', 'work_history', [UserRole.ADMIN]),
  ];

  navItems = [
    new NavItem('dashboard', 'dashboard.nav', 'dashboard'),
    new NavItem('workflows', 'workflow.nav', 'rebase'),
    new NavItem('dataconnection', 'dataconnection.nav', 'settings_input_component'),
    new NavItem(
      'jobs',
      'job.nav',
      'work',
      undefined,
      undefined,
      ['open-jobs', 'done-jobs', 'cancelled-jobs', 'deleted-jobs', 'all-jobs'],
    ),
    new NavItem('software', 'software.nav', 'download'),
    // TODO: Activate when B2B roles are implemented
    // new NavItem('configurations', 'configurations.nav', 'manufacturing'),
    new NavItem('users', 'user.nav', 'person'),
    new NavItem('devices', 'device.nav', 'devices'),
    new NavItem('', 'admin.nav', 'manage_accounts', [UserRole.ADMIN], this.adminItems),
    new NavItem('support', 'support.nav', 'support'),
  ];

  updateMobileNavExpanded(expanded: boolean) {
    this.zone.run(() => {
      this._mobileNavExpanded = expanded;
    });
  }

  updateSidebarCollapsed(collapsed: boolean) {
    this.zone.run(() => {
      this._sidebarCollapsed = collapsed;
    });
  }
}
