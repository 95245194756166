import { Component } from '@angular/core';
import { AuthService, LanguageService } from '@nimmsta/ng-commons-lib';

@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>',
})
export class AppComponent {
  constructor(private languageService: LanguageService, private authService: AuthService) {
    this.authService.loadCurrentUser().subscribe({
      complete: () => {
        this.languageService.changeLanguage(this.languageService.getPreferredLanguage());
      },
      error: () => {
        this.languageService.changeLanguage(this.languageService.getPreferredLanguage());
      },
    });
  }
}
