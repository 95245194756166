import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { P500Component } from '@nimmsta/ng-commons-lib';
import { ProductFruitsService } from '@services/productfruits.service';

@Component({
  selector: 'app-p500-ext',
  template: '<app-p500></app-p500>',
})
export class P500ExtComponent extends P500Component implements OnInit {
  constructor(protected override location: Location, private productFruits: ProductFruitsService) {
    super(location);
  }

  ngOnInit(): void {
    this.productFruits.destroy();
  }
}
