import { Component, Input } from '@angular/core';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { CardDataItem } from '@models/cardDataItem';

_('OPEN');
_('IN_PROGRESS');
_('COMPLETE');
_('CANCELLED');
_('DELETED');
_('Active_Worker');
_('Inactive_Worker');
_('Manager');
_('Loading');
_('ACTIVE');
_('INACTIVE');

@Component({
  selector: 'app-card-bar',
  templateUrl: './card-bar.component.html',
  styleUrls: ['./card-bar.component.scss'],
})
export class CardBarComponent {
  @Input()
    title: string = '';

  @Input()
    data: CardDataItem[] = [];

  _sum: number = 0;

  get sum(): number {
    this._sum = 0;
    this.data.forEach((item) => {
      this._sum += item.count;
    });
    return this._sum;
  }
}
