<div class="tree-wrapper">
  @if (parent()?.parent) {
    <a [routerLink]="['/help', parent()!.parent!.id]" class="ps-0 btn btn-link d-flex flex-row align-items-center">
      <mat-icon class="mat-icon-rtl-mirror">turn_left</mat-icon>
      <h5 class="mb-0">{{'contents.moveUp' | translate}}</h5>
    </a>
  } @else if(parent()) {
    <a [routerLink]="['/help']" class="ps-0 btn btn-link d-flex flex-row align-items-center">
      <mat-icon class="mat-icon-rtl-mirror">turn_left</mat-icon>
      <h5 class="mb-0">{{'contents.moveUp' | translate}}</h5>
    </a>
  }
  <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
    <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
      <a [routerLink]="['/help', node.id]"><h5 class="mb-0">{{node.name}}</h5></a>
    </mat-tree-node>
    <mat-nested-tree-node *matTreeNodeDef="let node; when: isFolder">
      <div class="mat-tree-node hoverable">
        <div class="d-inline-flex flex-column align-items-start">
          <div class="d-inline-flex flex-row align-items-center">
            <button mat-icon-button matTreeNodeToggle
                    [attr.aria-label]="'Toggle ' + node.name">
              <mat-icon class="mat-icon-rtl-mirror">
                {{treeControl.isExpanded(node) || !node.children?.length ? 'expand_more' : 'chevron_right'}}
              </mat-icon>
            </button>
            <a matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.name">
              <h5 class="mb-0">
                {{node.name}}
                @if (node.children?.length === 1) {
                  <small>({{'contents.child' | translate}})</small>
                } @else {
                  <small>({{'contents.children' | translate : {count: node.children?.length || 0} }})</small>
                }
              </h5>
            </a>
          </div>
          @if (node.content) {
            <div class="folder-description">{{node.content}}</div>
          }
        </div>
      </div>
      <div [class.invisible-node]="!treeControl.isExpanded(node) && node.children?.length"
           role="group">
        <ng-container matTreeNodeOutlet></ng-container>
      </div>
    </mat-nested-tree-node>
  </mat-tree>

  <app-error [error]="error"></app-error>
</div>
