import { Component, ViewChild } from '@angular/core';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { environment } from '@environments/environment';
import { TabDirective, TabsetComponent } from 'ngx-bootstrap/tabs';

_('support.title');

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
})
export class SupportComponent {
  private lastTab?: TabDirective;

  @ViewChild('tabset') tabs!: TabsetComponent;

  getDoc1Link(): string {
    return environment.documentation1Url;
  }

  onSelectTab(tab: TabDirective) {
    this.lastTab = tab;
  }

  openOverview() {
    window.open('https://nimmsta.atlassian.net/servicedesk/customer/portals', '_blank');
    // We need to delay this because the active property gets set after this event
    setTimeout(() => {
      if (this.lastTab) {
        this.lastTab.active = true;
      } else {
        this.tabs.tabs[0].active = true;
      }
    }, 250);
  }
}
