@if (success) {
  <div class="alert alert-success mt-3">
    <span class="material-icons-outlined me-2">error</span>
    <strong translate>support.success</strong>&nbsp;<span translate>support.ticket.send_success</span>
  </div>
}

@if (!success) {
  <form [formGroup]="form" class="card">
    <div class="row ps-1 pe-1">
      <div class="col-md-12 form-group">
        <label translate>support.ticket.requestType</label>
        <app-input-with-error [control]="this.form.controls['requestType']" [inputType]="'select'"
        [selectValues]="requestTypes.values" [selectNames]="requestTypes.names"></app-input-with-error>
      </div>
      <div class="col-md-12 form-group">
        <label translate>support.ticket.summary</label>
        <app-input-with-error [control]="this.form.controls['summary']"></app-input-with-error>
      </div>
      <div class="col-md-12 form-group mt-2">
        <label translate>support.ticket.description</label>
        <app-input-with-error [control]="this.form.controls['description']" inputType="textarea"></app-input-with-error>
      </div>
      <div class="col-md-12 form-group">
        <label translate>support.ticket.contactEmail</label>
        <app-input-with-error [control]="this.form.controls['contactEmail']"></app-input-with-error>
      </div>
      <div class="col-md-12 form-group">
        <label translate>support.ticket.attachment</label>
        <br />
        <a (click)="fileInput?.click()">
          <span class="material-symbols-outlined text-secondary">upload</span>
          <span translate>support.ticket.upload_attachment</span>
        </a>
        <input #fileInput (change)="filesChanged($event)" class="d-none" type="file">
        @if (attachment !== undefined) {
          <div class="alert alert-primary alert-dismissible fade show pt-1 pb-1 mt-2 col-12"
            >
            {{attachment.name}}
            <button (click)="removeFile()" type="button" class="btn-close" style="padding-top: 0; top: -1px;"
            aria-label="Remove"></button>
          </div>
        }
        <div class="col-md-12 form-group">
          <label translate>support.ticket.label_allow_tenant_access</label>
          <app-input-with-error [control]="this.form.controls['allowTenantAccess']" [inputType]="'checkbox'"
            description="support.ticket.allow_tenant_access">
          </app-input-with-error>
        </div>
        @if (allowTenantAccess) {
          <div class="col-md-12 form-group">
            <label translate>support.ticket.label_gdpr</label>
            <app-input-with-error [control]="this.form.controls['gdpr']" [inputType]="'checkbox'"
              description="support.ticket.gdpr">
            </app-input-with-error>
          </div>
        }
      </div>
      <div class="col-md-12 form-group mt-2">
        <app-error [error]="error"></app-error>
      </div>
      <div class="mt-3">
        <button [disabled]="!form.valid" type="button" class="btn btn-primary w-100" (click)="save()" [ladda]="loading"
        translate="support.ticket.create"></button>
      </div>
    </div>
  </form>
}
