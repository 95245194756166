<div class="d-flex position-absolute h-100 w-100 register-area">
  <div class="display-large">
    <div class="login-container h-100">
      <a href="/">
        <img src="./assets/img/logo.png" class="logo" />
      </a>
      <form
        class="d-flex flex-column justify-content-center mt-4"
        [formGroup]="registerForm"
        (ngSubmit)="onSubmit()"
        >
        @if (!isSubmitted) {
          <div>
            <h1 translate>register.signup</h1>
            <p translate>register.create-account</p>
            <div class="mb-3">
              <label for="organization" class="form-label" translate>register.company-name</label>
              <input
                id="organization"
                class="form-control"
                type="text"
                formControlName="organization"
                placeholder="Company name"
                />
              <div class="form-text" translate>
                register.enter-company-name
              </div>
              <div class="invalid-feedback">
                @if (registerForm.controls.organization.errors?.['required']) {
                  <div translate>
                    error.required
                  </div>
                }
              </div>
            </div>
            <div class="mb-3">
              <label for="firstName" class="form-label" translate>user.given_name</label>
              <input
                id="firstName"
                class="form-control"
                type="text"
                formControlName="firstName"
                placeholder="First name"
                />
              <div class="form-text" translate>
                register.enter-firstName
              </div>
              <div class="invalid-feedback">
                @if (registerForm.controls.firstName.errors?.['required']) {
                  <div translate>
                    error.required
                  </div>
                }
              </div>
            </div>
            <div class="mb-3">
              <label for="lastName" class="form-label" translate>user.family_name</label>
              <input
                id="lastName"
                class="form-control"
                type="text"
                formControlName="lastName"
                placeholder="Last name"
                />
              <div class="form-text" translate>
                register.enter-lastName
              </div>
              <div class="invalid-feedback">
                @if (registerForm.controls.lastName.errors?.['required']) {
                  <div translate>
                    error.required
                  </div>
                }
              </div>
            </div>
            <div class="mb-3">
              <label for="email" class="form-label" translate>user.email</label>
              <input
                id="email"
                class="form-control"
                type="email"
                formControlName="email"
                placeholder="name@example.com"
                />
              <div class="form-text" translate>register.enter-email</div>
              <div class="invalid-feedback">
                @if (registerForm.controls.email.errors?.['required']) {
                  <div translate>
                    error.required
                  </div>
                }
                @if (registerForm.controls.email.errors?.['email']) {
                  <div translate>
                    error.email
                  </div>
                }
              </div>
            </div>
            <div class="mb-3">
              <label for="email_repeat" class="form-label" translate>user.confirm-email</label>
              <input
                id="email_repeat"
                class="form-control"
                type="email"
                formControlName="email_repeat"
                placeholder="name@example.com"
                />
              <div class="form-text" translate>
                register.enter-confirm-email
              </div>
              <div class="invalid-feedback">
                @if (registerForm.controls.email_repeat.errors?.['required']) {
                  <div
                    translate
                    >
                    error.required
                  </div>
                }
                @if (registerForm.controls.email_repeat.errors?.['email']) {
                  <div translate>
                    error.email
                  </div>
                }
                @if (registerForm.controls.email_repeat.errors?.['not_the_same']) {
                  <div translate>
                    error.not_matching
                  </div>
                }
              </div>
            </div>
            <div class="mb-3">
              <label for="password" class="form-label" translate>login.password</label>
              <input
                id="password"
                class="form-control"
                type="password"
                formControlName="password"
                placeholder="********"
                />
              <div class="form-text" translate>
                register.password-text
              </div>
              <div class="invalid-feedback">
                @if (registerForm.controls.password.errors?.['required']) {
                  <div translate>
                    error.required
                  </div>
                }
                @if (registerForm.controls.password.errors?.['minlength']) {
                  <div translate>
                    error.password.minlength
                  </div>
                }
                @if (registerForm.controls.password.errors?.['hasNumber']) {
                  <div translate>
                    error.password.hasNumber
                  </div>
                }
                @if (registerForm.controls.password.errors?.['hasCapitalCase']) {
                  <div translate>
                    error.password.hasUpperCase
                  </div>
                }
                @if (registerForm.controls.password.errors?.['hasSmallCase']) {
                  <div translate>
                    error.password.hasLowerCase
                  </div>
                }
                @if (registerForm.controls.password.errors?.['hasSpecialCharacters']) {
                  <div translate>
                    error.password.hasSpecialCharacter
                  </div>
                }
              </div>
            </div>
            <div class="mb-3">
              <label for="password_repeat" class="form-label" translate>register.password-repeat</label>
              <input
                id="password_repeat"
                class="form-control"
                type="password"
                formControlName="password_repeat"
                placeholder="********"
                />
              <div class="form-text" translate>
                register.enter-password-repeat
              </div>
              <div class="invalid-feedback">
                @if (registerForm.controls.password_repeat.errors?.['required']) {
                  <div translate>
                    error.required
                  </div>
                }
                @if (registerForm.controls.password_repeat.errors?.['not_matching']) {
                  <div translate>
                    error.not_matching
                  </div>
                }
              </div>
            </div>
            <app-error [error]="error"></app-error>
            <button
              type="submit"
              class="btn btn-primary w-100 mt-4 mb-5"
              [class.disabled]="!registerForm.valid"
              [ladda]="isLoading"
              >
              {{ 'register.create' | translate }}
            </button>
            <p>
              <span translate>register.already-account</span>
              <a href="/" translate>login.login</a>
              <span translate>register.continue</span>
            </p>
          </div>
        }
      </form>
      @if (isSubmitted) {
        <div>
          <h1 translate>register.thanks</h1>
          <p>
            <span translate>register.verification</span>
            {{ this.registerForm.controls.email.value }}
          </p>
          <p class="mt-5" translate>
            register.email-missing
          </p>
          <button
            class="btn btn-primary w-100 mt-4 mb-5"
            [class.disabled]="!registerForm.valid"
            [ladda]="isLoading"
            (click)="resendVerification()"
            >
            {{ 'register.resend' | translate }}
          </button>
        </div>
      }
    </div>
  </div>
  <div class="w-50 d-none d-md-block">
    <app-product-placement></app-product-placement>
  </div>
</div>
