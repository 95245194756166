<div class="box">
  <div class="box-row header">
    <app-header-bar [date]="date" [title]="'dashboard.title'"></app-header-bar>
  </div>
  <app-loading-indicator [loading]="isLoading"></app-loading-indicator>
  <div class="content mb-3">
    <div class="row mt-4">
      <div class="col-lg-4">
        <div class="card test-card">
          <div class="card-body">
            <a [routerLink]="'/devices'" [queryParams]="{tab: 'scanner'}" class="card-title h5">
              <span class="material-symbols-outlined">devices</span>
              <span translate>smart-watches</span>
            </a>
            <h6 class="card-subtitle mb-2 mt-2 text-muted" translate>registered-smart-watches</h6>
            <app-card-bar [title]="'device.title'" [data]="hs50Data"></app-card-bar>
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="card">
          <div class="card-body">
            <a [routerLink]="'/users'" class="card-title h5">
              <span class="material-symbols-outlined">person</span>
              <span translate>user.title</span>
            </a>
            <h6 class="card-subtitle mb-2 mt-2 text-muted" translate>registered-employees</h6>
            <app-card-bar [title]="'user.title'" [data]="workerData"></app-card-bar>
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="card">
          <div class="card-body">
            <a [routerLink]="'/jobs'" class="card-title h5">
              <span class="material-symbols-outlined">work</span>
              <span translate>jobs</span>
            </a>
            <h6 class="card-subtitle mb-2 mt-2 text-muted" translate>work-last-24h</h6>
            <app-card-bar [title]="'jobs'" [data]="jobData"></app-card-bar>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-lg-12">
        <div class="card">
          <div class="d-flex">
            <a class="mb-0 h2" [routerLink]="['/workflows']" translate>workflow.title</a>
            <a (click)="navigateTo('workflows')" class="ms-auto" translate>view_all</a>
          </div>
          <!-- <p translate>dashboard.all_active_workflows</p> -->
          <div class="d-flex flex-column">
            <div class="table-responsive">
              <table style="max-height: 500px;">
                <thead>
                  <tr>
                    <th scope="col" translate>workflow.name</th>
                    <!-- <th scope="col">
                    Jobs completed<br>
                    (last/previous 24h)
                  </th> -->
                  <th scope="col" translate>workflow.description</th>
                  <!-- <th scope="col" translate>workflow.status</th> -->
                </tr>
              </thead>
              <tbody>
                @for (item of workflows; track item.id) {
                  <tr>
                    <td>{{item.name}}</td>
                    <td>{{item.description}}</td>
                    <!--<td>
                    <span class="fw-bold">{{item.jobsCompleted}}</span>
                    <span class="me-3">&nbsp;/&nbsp;{{item.allJobs}}</span> -->
                    <!--<span *ngIf="item.jobsCompleted < item.allJobs" class="material-symbols-outlined">arrow_upward</span>
                    <span *ngIf="item.jobsCompleted > item.allJobs" class="material-symbols-outlined">arrow_downward</span>
                    <span *ngIf="item.jobsCompleted === item.allJobs" class="material-symbols-outlined">arrow_forward</span>
                  </td>-->
                  <!-- <td><div class="badge rounded-pill text-bg-light"><div class="dot" [class]="getStatusString(item.status).replaceAll(' ', '-')"></div>{{getStatusString(item.status).toUpperCase()| translate}}</div></td> -->
                </tr>
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="col-lg-6">
  <div class="card">
    <div class="d-flex mb-5">
      <h2>Reports</h2>
      <div class="dropdown ms-auto">
        <a class="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
          Performance chart visualisation
        </a>
        <ul class="dropdown-menu">
          <li><a class="dropdown-item">Other chart</a></li>
          <li><a class="dropdown-item">Some other chart</a></li>
        </ul>
      </div>
    </div>
    <app-line-chart></app-line-chart>
  </div>
</div> -->
</div>
</div>
</div>
