export class CardDataItem {
  title: string;

  count: number;

  color?: string;

  constructor(title: string, count: number, color?: string) {
    this.title = title;
    this.count = count;
    this.color = color;
  }
}
