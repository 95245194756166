import { NavItem, NavItemService, UserRole } from '@nimmsta/ng-commons-lib';
import { Observable, of } from 'rxjs';

export class WorkflowNavItemService implements NavItemService {
  getSidebarNavItems(): Observable<NavItem[]> {
    return of([
      new NavItem('dashboard', 'dashboard.nav', 'dashboard'),
      new NavItem('workflows', 'workflow.nav', 'rebase'),
      new NavItem('dataconnection', 'dataconnection.nav', 'settings_input_component'),
      new NavItem(
        'jobs',
        'job.nav',
        'work',
        undefined,
        undefined,
        ['open-jobs', 'done-jobs', 'cancelled-jobs', 'deleted-jobs', 'all-jobs'],
      ),
      new NavItem('software', 'software.nav', 'download'),
      // TODO: Activate when B2B roles are implemented
      // new NavItem('configurations', 'configurations.nav', 'manufacturing'),
      new NavItem('users', 'user.nav', 'person'),
      new NavItem('devices', 'device.nav', 'devices'),
      new NavItem('support', 'support.nav', 'support'),
    ]);
  }

  getHeaderBarNavItems(): Observable<NavItem[]> {
    return of([
      new NavItem('workflow-emails', 'workflow-email.nav', 'email'),
      new NavItem(
        '',
        'admin.nav',
        'manage_accounts',
        [UserRole.ADMIN],
        [ // sub items
          new NavItem('workflow-templates', 'workflow.default.nav', 'star', [UserRole.ADMIN]),
          new NavItem(
            'dataconnection-templates',
            'dataconnection.template.nav',
            'settings_input_component',
            [UserRole.ADMIN],
          ),
          new NavItem('organizations', 'organizations.nav', 'groups', [UserRole.ADMIN]),
          new NavItem('admin-jobs', 'job.admin.nav', 'work_history', [UserRole.ADMIN]),
        ],
        [],
        true, // expanded
      ),
    ]);
  }
}
