// TODO Remove after adding load function back
/* eslint-disable @typescript-eslint/no-unused-vars */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import {
  Observable, catchError, map, of, tap,
} from 'rxjs';

export interface DiscoveryDocumentConfig {
  api_endpoints: {
    api: string;
    documentation: string;
  };
  oidc: {
    endpoint: string;
    client_id: string;
  };
  productfruits_key: string;
}

export interface DiscoveryDocument {
  production: DiscoveryDocumentConfig;
  development: DiscoveryDocumentConfig;
  local: DiscoveryDocumentConfig;
}

@Injectable({
  providedIn: 'root',
})
export class DiscoveryDocumentService {
  data?: DiscoveryDocument;

  constructor(private http: HttpClient) { }

  load(defaults?: Partial<DiscoveryDocument>): Observable<DiscoveryDocument | undefined> {
    // return this.http.get<DiscoveryDocument>(environment.discoveryDocument)
    //   .pipe(
    //     map((discoveryDocument: DiscoveryDocument) => ({ ...defaults || {}, ...discoveryDocument || {} })),
    //     catchError(() => of(undefined as unknown as DiscoveryDocument)),
    //     tap(
    //       (discoveryDocument?: DiscoveryDocument) => {
    //         this.data = discoveryDocument;
    //       },
    //     ),
    //   );
    return of(undefined);
  }
}
