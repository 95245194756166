import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { SupportEmailRequest } from '@models/supportEmailRequest';
import { JiraTicketRequest } from '@models/jiraTicketRequest';

@Injectable({
  providedIn: 'root',
})
export class SupportService {
  url: string;

  constructor(private http: HttpClient) {
    this.url = `${environment.workflowUrl}/support/`;
  }

  createTicket(request: JiraTicketRequest, attachment?: File): Observable<null> {
    const formData = new FormData();
    formData.append('data', JSON.stringify(request));
    if (attachment !== undefined) {
      formData.append('attachment', attachment);
    }
    return this.http.post<null>(`${this.url}createJiraTicket`, formData);
  }

  sendEmail(email: SupportEmailRequest): Observable<SupportEmailRequest> {
    return this.http.post<SupportEmailRequest>(`${this.url}createSupportEmail`, email);
  }
}
