import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { QRCodeModule } from 'angularx-qrcode';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { MarkdownModule } from 'ngx-markdown';
import { FormsModule } from '@angular/forms';
import { MatPaginatorModule } from '@angular/material/paginator';
import { DatePipe, NgOptimizedImage, registerLocaleData } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';
import * as de from '@angular/common/locales/de';
import { ProductPlacementComponent } from '@components/product-placement/product-placement.component';
import { CardBarComponent } from '@components/card-bar/card-bar.component';
import { RegisterComponent } from '@pages/register/register.component';
import { P404ExtComponent } from '@pages/error/p404.ext.component';
import { ForgotPasswordComponent } from '@pages/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from '@pages/reset-password/reset-password.component';
import { DashboardComponent } from '@pages/dashboard/dashboard.component';
import { SupportComponent } from '@pages/support/support.component';
import { TicketFormComponent } from '@pages/support/ticket-form.component';
import { EmailFormComponent } from '@pages/support/email-form.component';
import { VerifyEmailComponent } from '@pages/verify-email/verify-email.component';
import { WaitingListComponent } from '@pages/waiting-list/waiting-list.component';

import { DiscoveryDocument, DiscoveryDocumentService } from '@services/discovery-document.service';
import { MatInputModule } from '@angular/material/input';
import WebFont from 'webfontloader';
import { MultiSelectModule } from 'primeng/multiselect';
import { MonacoEditorModule } from 'ngx-monaco-editor-v2';
import { P500ExtComponent } from '@pages/error/p500.ext.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ProductFruitsService } from '@services/productfruits.service';
import { KeycloakAngularModule } from 'keycloak-angular';
import { JobModule, SoftwareModule, WorkflowModule } from '@nimmsta/ng-workflow-lib';
import { NimmstaCommonsModule } from '@nimmsta/ng-commons-lib';
import { environment } from '@environments/environment';
import { TimeagoPipe } from 'ngx-timeago';
import { PagesModule } from '@nimmsta/ng-b2b-lib';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { LayoutComponent } from './layout/layout.component';

WebFont.load({
  custom: { families: ['Material Icons', 'Material Symbols Outlined'] },
});

@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    RegisterComponent,
    P404ExtComponent,
    P500ExtComponent,
    ProductPlacementComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    VerifyEmailComponent,
    DashboardComponent,
    CardBarComponent,
    SupportComponent,
    TicketFormComponent,
    EmailFormComponent,
    WaitingListComponent,
  ],
  imports: [
    AppRoutingModule,
    MatDividerModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ModalModule.forRoot(),
    TabsModule.forRoot(),
    BsDatepickerModule.forRoot(),
    QRCodeModule,
    MatProgressSpinnerModule,
    MarkdownModule.forRoot(),
    FormsModule,
    MatPaginatorModule,
    MatInputModule,
    NgOptimizedImage,
    MultiSelectModule,
    MonacoEditorModule.forRoot(),
    KeycloakAngularModule,
    NimmstaCommonsModule.forRoot({
      apiUrls: {
        workflow: environment.workflowUrl,
        configurations: environment.configurationUrl,
      },
      applicationUrls: {},
      defaultPageSizeOptions: environment.defaultPageSizeOptions,
      defaultPageSize: environment.defaultPageSize,
      USER_KEY: environment.USER_KEY,
      keycloakUrl: environment.keycloakUrl,
      keycloakRealm: environment.keycloakRealm,
      keycloakClientId: environment.keycloakClientId,
      defaultConfigurationVersion: environment.defaultConfigurationVersion,
    }),
    WorkflowModule,
    JobModule,
    SoftwareModule,
    PagesModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: (service: DiscoveryDocumentService) => () => service.load({} as DiscoveryDocument),
      deps: [DiscoveryDocumentService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (service: ProductFruitsService) => () => service.serviceInit(),
      deps: [ProductFruitsService],
      multi: true,
    },
    DatePipe,
    TimeagoPipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor() {
    registerLocaleData(de.default);
  }
}
