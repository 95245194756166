export class WorkflowStats {
  name: string;

  status: WorkflowStatus;

  jobsCompleted: number;

  allJobs: number;

  constructor(name: string, status: WorkflowStatus, jobsCompleted: number, allJobs: number) {
    this.name = name;
    this.status = status;
    this.jobsCompleted = jobsCompleted;
    this.allJobs = allJobs;
  }
}

export enum WorkflowStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}
