import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { P404Component } from '@nimmsta/ng-commons-lib';
import { ProductFruitsService } from '@services/productfruits.service';

@Component({
  selector: 'app-p404-ext',
  template: '<app-p404></app-p404>',
})
export class P404ExtComponent extends P404Component implements OnInit {
  constructor(protected override location: Location, private productFruits: ProductFruitsService) {
    super(location);
  }

  ngOnInit(): void {
    this.productFruits.destroy();
  }
}
