import { Component } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
})
export class HelpComponent {
  error?: HttpErrorResponse;
}
