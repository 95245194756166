import {
  Component, OnChanges, OnInit, input,
} from '@angular/core';
import {
  ContentItem, ContentTreeItem, ContentType, ContentsService,
} from '@nimmsta/ng-b2b-lib';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { NestedTreeControl } from '@angular/cdk/tree';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-help-tree',
  templateUrl: './help-tree.component.html',
  styleUrls: ['./help-tree.component.scss'],
})
export class HelpTreeComponent implements OnInit, OnChanges {
  parent = input<ContentItem | undefined>();

  treeControl = new NestedTreeControl<ContentTreeItem>((node) => node.children);

  dataSource = new MatTreeNestedDataSource<ContentTreeItem>();

  isFolder = (_: number, node: ContentTreeItem) => node.type === ContentType.FOLDER;

  error?: HttpErrorResponse;

  constructor(
    private contentsService: ContentsService,
  ) {
  }

  ngOnInit() {
    this.load();
  }

  ngOnChanges() {
    this.load();
  }

  private load() {
    const parent = this.parent();
    if (parent) {
      this.contentsService.getTreeOfParent(parent.id).subscribe({
        next: (tree) => {
          this.dataSource.data = tree || [];
        },
        error: (error) => {
          this.error = error;
        },
      });
    } else {
      this.contentsService.getTree().subscribe({
        next: (tree) => {
          this.dataSource.data = tree || [];
        },
        error: (error) => {
          this.error = error;
        },
      });
    }
  }
}
