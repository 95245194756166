<app-default-layout (collapseState)="updateSidebarCollapsed($event)" (mobileExpandedState)="updateMobileNavExpanded($event)">
  <app-default-menu slot="sidebar" [collapsed]="_sidebarCollapsed"></app-default-menu>
  <app-default-menu slot="mobile" [mobileExpanded]="_mobileNavExpanded"></app-default-menu>
</app-default-layout>

<div class="position-absolute fw-light" style="bottom: 0; right: 0.2rem; color: gray; font-size: 0.6em;">
  @if (frontendVersion) {
    <span>F.{{frontendVersion.tag ? frontendVersion.tag : frontendVersion.hash}}</span>
  }
  @if (frontendVersion && backendVersion) {
    <span>&nbsp;-&nbsp;</span>
  }
  @if (backendVersion) {
    <span>B.{{backendVersion.tag ? backendVersion.tag : backendVersion.hash}}</span>
  }
</div>
