<div class="box">
  <div class="box-row header">
    <app-header-bar [backRoute]="'/help'" [title]="contentItem?.name || ''"></app-header-bar>
  </div>
  @if (contentItem?.type === ContentType.PAGE && contentItem?.parent?.id) {
    <a [routerLink]="['/help', contentItem?.parent?.id]" class="ps-0 btn btn-link d-flex flex-row align-items-center">
      <mat-icon class="mat-icon-rtl-mirror">turn_left</mat-icon>
      <h5 class="mb-0">{{'contents.moveUp' | translate}}</h5>
    </a>
  } @else if(contentItem?.type === ContentType.PAGE) {
    <a [routerLink]="['/help']" class="ps-0 btn btn-link d-flex flex-row align-items-center">
      <mat-icon class="mat-icon-rtl-mirror">turn_left</mat-icon>
      <h5 class="mb-0">{{'contents.moveUp' | translate}}</h5>
    </a>
  }
  @switch (contentItem?.type) {
    @case (ContentType.PAGE) {
      <div class="p-3 bg-white">
        <quill-view [content]="contentItem?.content || ''"></quill-view>
      </div>
    }
    @case (ContentType.FOLDER) {
      @if (contentItem?.content) {
        <div>{{contentItem!.content}}</div>
      }
      @if (contentItem?.children && contentItem!.children!.length) {
        <app-help-tree [parent]="contentItem"></app-help-tree>
      } @else {
        <h4 translate>contents.noChildren</h4>
      }
    }
  }

  <app-error [error]="error"></app-error>
</div>
