import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { AbstractControlOptions, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AuthService, CustomValidators } from '@nimmsta/ng-commons-lib';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent {
  public resetForm = this.fb.group(
    {
      reset_code: ['', [Validators.required]],
      password: ['', [Validators.required].concat(AuthService.pwdValidators)],
      password_repeat: ['', [Validators.required]],
    },
    { validators: CustomValidators.samePassword } as AbstractControlOptions,
  );

  public isLoading = false;

  public isResetPasswordLoading = false;

  public submitted = false;

  public verifiedSuccessfully: boolean = false;

  public error?: HttpErrorResponse;

  code: string | undefined;

  username: string | undefined;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private authService: AuthService,
  ) {
    this.code = this.route.snapshot.queryParams['code'];
    this.username = this.route.snapshot.queryParams['username'];
    if (this.code) {
      this.resetForm.controls.reset_code.setValue(this.code);
      this.resetForm.controls.reset_code.disable();
    }
    if (!this.username || !this.code) {
      this.error = new HttpErrorResponse({
        error: { error: 'Not a valid reset password link. Only use the link provided in the email to reset your password.' },
        status: 400,
      });
    }
  }

  onSubmit() {
    if (this.code === undefined) {
      this.code = this.resetForm.controls.reset_code.value!;
    }
    if (this.code && this.username && this.resetForm.valid) {
      this.code = this.resetForm.controls.reset_code.value!; // in case user changed the code after the page was loaded
      this.isLoading = true;
      this.error = undefined;
      const password = this.resetForm.controls.password.value!;
      this.authService.resetPassword(this.code, this.username, password).subscribe({
        next: () => {
          this.isLoading = false;
          this.submitted = true;
          this.verifiedSuccessfully = true;
        },
        error: (error) => {
          this.isLoading = false;
          this.error = error;
        },
      });
    }
  }

  onResendSubmit() {
    if (this.username) {
      this.isResetPasswordLoading = true;
      this.authService.forgotPassword(this.username).subscribe({
        next: () => {
          this.isResetPasswordLoading = false;
        },
        error: (error) => {
          this.isResetPasswordLoading = false;
          this.error = error;
        },
      });
    }
  }
}
