import { Component, NgZone } from '@angular/core';
import {
  Version, VersionService,
} from '@nimmsta/ng-commons-lib';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { VERSION } from '@environments/version';
import { environment } from '@environments/environment';

_('dashboard.nav');
_('worker.nav');
_('dataconnection.nav');
_('dataconnection.template.nav');
_('workflow.nav');
_('workflow.default.nav');
_('user.nav');
_('device.nav');
_('software.nav');
_('support.nav');
_('job.nav');
_('admin.nav');

@Component({
  templateUrl: './layout.component.html',
})
export class LayoutComponent {
  protected _sidebarCollapsed = false;

  protected _mobileNavExpanded = false;

  frontendVersion?: Version = VERSION;

  backendVersion?: Version;

  constructor(private zone: NgZone, private versionService: VersionService) {
    this.versionService.setBaseURL(environment.apiUrls.workflow);
    this.versionService.getBackendVersion().subscribe((version) => {
      this.zone.run(() => {
        this.backendVersion = version;
      });
    });
  }

  updateMobileNavExpanded(expanded: boolean) {
    this.zone.run(() => {
      this._mobileNavExpanded = expanded;
    });
  }

  updateSidebarCollapsed(collapsed: boolean) {
    this.zone.run(() => {
      this._sidebarCollapsed = collapsed;
    });
  }
}
