import { Injectable } from '@angular/core';
import { productFruits } from 'product-fruits';
import { environment } from '@environments/environment';
import { AuthService, LanguageService } from '@nimmsta/ng-commons-lib';

interface WindowWithProductFruits extends Window {
  productFruits?: {
    services?: {
      isLoaded: boolean;
      destroy(): void;
    };
  };
}

@Injectable({
  providedIn: 'root',
})
export class ProductFruitsService {
  constructor(
    private languageService: LanguageService,
    private auth: AuthService,

  ) { }

  public serviceInit() {
    this.languageService.languageChange.subscribe(() => {
      this.destroy();
      this.checkIfUserIsLoggedInAndInit();
    });
  }

  private checkIfUserIsLoggedInAndInit() {
    this.auth.getCurrentUser().subscribe((user) => {
      this.init({ username: user.email });
    });
  }

  private init(
    userObject: ProductFruitsUserObject,
    options?: ProductFruitsInitOptions | undefined,
  ) {
    const key = environment.PRODUCT_FRUITS_KEY;
    const lang = this.languageService.getPreferredLanguage();
    productFruits.init(key, lang, userObject, options);
  }

  private isLoaded() {
    return (window as unknown as WindowWithProductFruits).productFruits?.services?.isLoaded;
  }

  public destroy() {
    if (this.isLoaded()) {
      (window as WindowWithProductFruits).productFruits?.services?.destroy();
    }
  }
}
