import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { UserService } from '@nimmsta/ng-commons-lib';

_('verify-email.not_valid_email_confirm_link');

@Component({
  selector: 'verify-email-component',
  templateUrl: './verify-email.component.html',
})
export class VerifyEmailComponent {
  private code: string;

  private email: string;

  public error?: HttpErrorResponse;

  public loading?: boolean = false;

  public isVerifyEmailLoading = false;

  public verifiedSuccessfully: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
  ) {
    this.code = this.route.snapshot.queryParams['code'];
    this.email = this.route.snapshot.queryParams['email'];
    if (!this.code || !this.email) {
      this.error = new HttpErrorResponse({
        error: { error: 'verify-email.not_valid_email_confirm_link' },
        status: 400,
      });
    }
  }

  verifyEmail() {
    this.isVerifyEmailLoading = true;
    this.userService.verifyEmail(this.email, this.code).subscribe({
      next: () => {
        this.isVerifyEmailLoading = false;
        this.verifiedSuccessfully = true;
      },
      error: (error) => {
        this.isVerifyEmailLoading = false;
        this.error = error;
      },
    });
  }
}
