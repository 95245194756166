<div class="blocker-page">
  <div class="container">
    <div class="row">
      <div class="col-2">
        <div>
          <img src="./assets/img/logo.png" class="w-100 m-5" alt="Nimmsta Logo" />
        </div>
      </div>
      <div class="row rounded  p-4 blocker-page-content">
        <div class="col-8">
          <h1 translate>waiting-list.title</h1>
          <br />
          <p translate>waiting-list.top-text</p>
          <br />
          <p translate>waiting-list.support-text</p>
          <p><a href="mailto:support@nimmsta.com">support&#64;nimmsta.com</a></p>
          <br />
          <p translate>waiting-list.thanks-message</p>
          <p>NIMMSTA GmbH.</p>
        </div>
      </div>
      <div class="row mt-4">
        <button class="btn btn-primary w-25 mt-4 mb-5 position-absolute start-50 translate-middle" (click)="logout()"
          translate>logout</button>
      </div>
    </div>
  </div>
