import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { P404ExtComponent } from '@pages/error/p404.ext.component';
import { P500ExtComponent } from '@pages/error/p500.ext.component';
import { RegisterComponent } from '@pages/register/register.component';
import { ForgotPasswordComponent } from '@pages/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from '@pages/reset-password/reset-password.component';
import { DashboardComponent } from '@pages/dashboard/dashboard.component';
import { SupportComponent } from '@pages/support/support.component';
import { VerifyEmailComponent } from '@pages/verify-email/verify-email.component';
import { WaitingListComponent } from '@pages/waiting-list/waiting-list.component';
import {
  AdminJobComponent,
  AdminJobDetailComponent, DataConnectionComponent, DataConnectionTemplatesComponent, DeviceComponent,
  JobComponent,
  JobDetailComponent, MdmDetailComponent, ScannerDetailComponent,
  SoftwareArtifactReleaseTypeComponent, SoftwareArtifactSingleComponent,
  SoftwareComponent, SoftwareDetailComponent,
  SoftwarePlatformComponent,
  SoftwareTypeComponent,
  WorkflowComponent,
  WorkflowDetailComponent,
  WorkflowTemplateComponent,
} from '@nimmsta/ng-workflow-lib';
import { AuthGuard, ProfileComponent, ProfileSessionComponent } from '@nimmsta/ng-commons-lib';
import { ConfigurationCustomerWrapperComponent, UserOverviewComponent } from '@nimmsta/ng-b2b-lib';
import { LayoutComponent } from './layout/layout.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: '500',
    component: P500ExtComponent,
  },
  {
    path: 'verify-email',
    component: VerifyEmailComponent,
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
  },
  {
    path: 'reset-password',
    component: ResetPasswordComponent,
  },
  {
    path: 'register',
    component: RegisterComponent,
  },
  {
    path: 'waiting-list',
    component: WaitingListComponent,
  },
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: DashboardComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'workflows',
        component: WorkflowComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'workflow-templates',
        component: WorkflowTemplateComponent,
        canActivate: [AuthGuard, AuthGuard.isAdmin],
      },
      {
        path: 'workflows/:id',
        component: WorkflowDetailComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'users',
        component: UserOverviewComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'dataconnection',
        component: DataConnectionComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'dataconnection-templates',
        component: DataConnectionTemplatesComponent,
        canActivate: [AuthGuard, AuthGuard.isAdmin],
      },
      {
        path: 'jobs',
        component: JobComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'jobs/:id',
        component: JobDetailComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'admin-jobs',
        component: AdminJobComponent,
        canActivate: [AuthGuard, AuthGuard.isAdmin],
      },
      {
        path: 'admin-jobs/:id',
        component: AdminJobDetailComponent,
        canActivate: [AuthGuard, AuthGuard.isAdmin],
      },
      {
        path: 'software',
        component: SoftwareComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'softwareType',
        component: SoftwareTypeComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'softwarePlatform',
        component: SoftwarePlatformComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'softwareArtifactReleaseType',
        component: SoftwareArtifactReleaseTypeComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'software/:softwareId',
        component: SoftwareDetailComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'software/:softwareId/:artifactId',
        component: SoftwareArtifactSingleComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'configurations',
        component: ConfigurationCustomerWrapperComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'profile',
        component: ProfileComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'profile/sessions',
        component: ProfileSessionComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'support',
        component: SupportComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'devices',
        component: DeviceComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'devices/mdm/:id',
        component: MdmDetailComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'devices/smartwatches/:id',
        component: ScannerDetailComponent,
        canActivate: [AuthGuard],
      },
    ],
  },
  {
    path: '**',
    component: P404ExtComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
