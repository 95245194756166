import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { SupportEmailRequest } from '@models/supportEmailRequest';
import { SupportService } from '@services/support.service';
import { AuthService, EnhancedFormGroup } from '@nimmsta/ng-commons-lib';

@Component({
  selector: 'app-email-form',
  templateUrl: 'email-form.component.html',
})
export class EmailFormComponent implements OnInit {
  form!: EnhancedFormGroup<{ [key: string]: FormControl<string | null | undefined> }>;

  value: SupportEmailRequest;

  loading = false;

  error?: HttpErrorResponse;

  success = false;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private supportService: SupportService,
  ) {
    this.value = {} as SupportEmailRequest;
    this.setFormGroup();
  }

  ngOnInit(): void {
    this.authService.getCurrentUser().subscribe((user) => {
      this.form.controls['mail'].setValue(user?.email || '');
    });
  }

  protected setFormGroup(): void {
    this.form = this.fb.group({
      mail: [this.value.mail, [Validators.required, Validators.email]],
      title: [this.value.title, [Validators.required]],
      message: [this.value.message, [Validators.required]],
    });
  }

  save(): void {
    this.loading = true;
    this.value = Object.assign(this.value, this.form.value);
    if (this.form.valid) {
      this.supportService.sendEmail(this.value).subscribe({
        next: () => {
          this.loading = false;
          this.success = true;
        },
        error: (response: HttpErrorResponse) => {
          this.error = response;
          this.loading = false;
          this.success = false;
        },
      });
    } else {
      this.form.markAllAsTouched();
    }
  }
}
