import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { JiraTicketRequest } from '@models/jiraTicketRequest';
import { SupportService } from '@services/support.service';
import { AuthService, EnhancedFormGroup, User } from '@nimmsta/ng-commons-lib';

@Component({
  selector: 'app-ticket-form',
  templateUrl: 'ticket-form.component.html',
})
export class TicketFormComponent implements OnInit {
  form!: EnhancedFormGroup<{ [key: string]: FormControl<string | number | boolean | null | undefined> }>;

  value: JiraTicketRequest;

  attachment?: File;

  loading = false;

  error?: HttpErrorResponse;

  success = false;

  allowTenantAccess: boolean = false;

  requestTypes = {
    names: [
      'support.ticket.requestTypes.full_care',
      'support.ticket.requestTypes.misc',
      'support.ticket.requestTypes.bug',
      'support.ticket.requestTypes.feature_request',
      'support.ticket.requestTypes.technical_support',
      'support.ticket.requestTypes.productivity_cloud',
    ],
    values: [
      17, // support.ticket.requestTypes.full_care
      18, // support.ticket.requestTypes.misc
      19, // support.ticket.requestTypes.bug
      20, // support.ticket.requestTypes.feature_request
      21, // support.ticket.requestTypes.technical_support
      23, // support.ticket.requestTypes.creativity_cloud
    ],
  };

  user: User | null = null;

  constructor(
    private fb: FormBuilder,
    private supportService: SupportService,
    private authService: AuthService,
  ) {
    this.value = {
      contactEmail: this.user?.email || '',
    } as JiraTicketRequest;
    this.setFormGroup();

    this.form.controls['allowTenantAccess'].valueChanges.subscribe((value) => {
      this.allowTenantAccess = value as boolean;
    });
  }

  ngOnInit(): void {
    this.authService.getCurrentUser().subscribe((user) => {
      this.user = user;
      this.form.controls['contactEmail'].setValue(user?.email || '');
    });
  }

  protected filesChanged(e: Event) {
    this.attachment = (e?.target as HTMLInputElement).files?.item(0) as File;
  }

  protected removeFile() {
    this.attachment = undefined;
  }

  protected setFormGroup(): void {
    this.form = this.fb.group({
      summary: [this.value.summary, [Validators.required]],
      description: [this.value.description, [Validators.required]],
      contactEmail: [this.value.contactEmail, [Validators.required, Validators.email]],
      requestType: [this.value.requestType || 21, [Validators.required]],
      allowTenantAccess: [false],
      gdpr: [false],
    });
  }

  protected save(): void {
    this.loading = true;
    this.value = Object.assign(this.value, this.form.value);
    if (this.form.valid) {
      this.supportService.createTicket(this.enrichMessage(this.value), this.attachment).subscribe({
        next: () => {
          this.loading = false;
          this.success = true;
        },
        error: (response: HttpErrorResponse) => {
          this.error = response;
          this.loading = false;
          this.success = false;
        },
      });
    } else {
      this.form.markAllAsTouched();
    }
  }

  /**
   * Extends the message with additional tenant information (if allowed)
   */
  private enrichMessage(request: JiraTicketRequest): JiraTicketRequest {
    // Enrich the message with tenant information
    const orginalMessage = request.description;
    request.description = `${orginalMessage}

---
Additional Information:
User Id: ${this.user?.sub || '(Error: Id not found.)'}
User Name: ${this.getProfileName()}`;

    if (this.form.value['allowTenantAccess']) {
      request.description = `${request.description}
Tenant Id: ${this.user?.organization?.id || '(Error: Id not found.)'}
Tenant Name: ${this.user?.organization?.name || '(Error: Name not found.)'}
GDPR accepted: ${this.form.value['gdpr'] ? 'Yes' : 'No'}`;
    }

    return request;
  }

  private getProfileName(): string {
    if (this.user && this.user.given_name && this.user.family_name) {
      return `${this.user.given_name} ${this.user.family_name}`;
    } else if (this.user && this.user.given_name) {
      return `${this.user.given_name}`;
    } else if (this.user && this.user.family_name) {
      return `${this.user.family_name}`;
    } else if (this.user) {
      return this.user.email;
    }
    return 'profile';
  }
}
