import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { OverviewData } from '@models/overviewData';
import { Pageable, Job, Workflow } from '@nimmsta/ng-commons-lib';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  constructor(protected http: HttpClient) {}

  getOverviewData(): Observable<OverviewData> {
    return this.http.get<OverviewData>(`${environment.apiUrls.workflow}/dashboard/quick-overview`);
  }

  getWorkflows(): Observable<Pageable<Workflow>> {
    return this.http.get<Pageable<Workflow>>(`${environment.apiUrls.workflow}/workflow`);
  }

  getJobStatistics(): Observable<Pageable<Job>> {
    return this.http.get<Pageable<Job>>(`${environment.apiUrls.workflow}/dashboard/jobs/All/Day`);
  }
}
