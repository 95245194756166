import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '@nimmsta/ng-commons-lib';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent {
  public resetForm = this.fb.group({
    email: ['', [Validators.required, Validators.email]],
  });

  public isLoading = false;

  public submitted = false;

  public error?: HttpErrorResponse;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
  ) {}

  onSubmit() {
    this.isLoading = true;
    this.error = undefined;
    const email = this.resetForm.controls.email.value!;
    this.authService.forgotPassword(email).subscribe({
      next: () => {
        this.isLoading = false;
        this.submitted = true;
      },
      error: (error) => {
        this.isLoading = false;
        this.error = error;
      },
    });
  }
}
