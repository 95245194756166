import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { QRCodeModule } from 'angularx-qrcode';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { QuillConfigModule, QuillModule } from 'ngx-quill';
import { MarkdownModule } from 'ngx-markdown';
import { FormsModule } from '@angular/forms';
import { MatPaginatorModule } from '@angular/material/paginator';
import { DatePipe, NgOptimizedImage, registerLocaleData } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';
import * as de from '@angular/common/locales/de';
import { ProductPlacementComponent } from '@components/product-placement/product-placement.component';
import { CardBarComponent } from '@components/card-bar/card-bar.component';
import { RegisterComponent } from '@pages/register/register.component';
import { P404ExtComponent } from '@pages/error/p404.ext.component';
import { ForgotPasswordComponent } from '@pages/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from '@pages/reset-password/reset-password.component';
import { DashboardComponent } from '@pages/dashboard/dashboard.component';
import { SupportComponent } from '@pages/support/support.component';
import { TicketFormComponent } from '@pages/support/ticket-form.component';
import { EmailFormComponent } from '@pages/support/email-form.component';
import { VerifyEmailComponent } from '@pages/verify-email/verify-email.component';

import { DiscoveryDocument, DiscoveryDocumentService } from '@services/discovery-document.service';
import { MatInputModule } from '@angular/material/input';
import WebFont from 'webfontloader';
import { MultiSelectModule } from 'primeng/multiselect';
import { MonacoEditorModule } from 'ngx-monaco-editor-v2';
import { P500ExtComponent } from '@pages/error/p500.ext.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ProductFruitsService } from '@services/productfruits.service';
import { KeycloakAngularModule } from 'keycloak-angular';
import {
  DeviceModule, JobModule, SoftwareModule, WorkflowModule,
} from '@nimmsta/ng-workflow-lib';
import {
  NimmstaCommonsModule, initQuill, defaultToolbar, NAV_ITEM_SERVICE,
} from '@nimmsta/ng-commons-lib';
import { environment } from '@environments/environment';
import { TimeagoPipe } from 'ngx-timeago';
import { PagesModule } from '@nimmsta/ng-b2b-lib';
import hljs from 'highlight.js';
import { HelpComponent } from '@pages/help/help.component';
import { HelpItemComponent } from '@pages/help/help-item.component';
import { MatTreeModule } from '@angular/material/tree';
import { MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { SortableModule } from 'ngx-bootstrap/sortable';
import { HelpTreeComponent } from '@pages/help/help-tree.component';
import { WorkflowNavItemService } from '@services/workflownav.service';
import { LayoutComponent } from './layout/layout.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

WebFont.load({
  custom: { families: ['Material Icons', 'Material Symbols Outlined'] },
});

initQuill();

@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    RegisterComponent,
    P404ExtComponent,
    P500ExtComponent,
    ProductPlacementComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    VerifyEmailComponent,
    DashboardComponent,
    CardBarComponent,
    SupportComponent,
    TicketFormComponent,
    EmailFormComponent,
    HelpComponent,
    HelpItemComponent,
    HelpTreeComponent,
  ],
  imports: [
    AppRoutingModule,
    MatDividerModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ModalModule.forRoot(),
    TabsModule.forRoot(),
    BsDatepickerModule.forRoot(),
    QRCodeModule,
    MatProgressSpinnerModule,
    MarkdownModule.forRoot(),
    FormsModule,
    MatPaginatorModule,
    MatInputModule,
    NgOptimizedImage,
    MultiSelectModule,
    MonacoEditorModule.forRoot(),
    KeycloakAngularModule,
    MatTreeModule,
    MatIcon,
    MatIconButton,
    TypeaheadModule,
    SortableModule,
    NimmstaCommonsModule.forRoot({
      apiUrls: environment.apiUrls,
      applicationUrls: environment.applicationUrls,
      defaultPageSizeOptions: environment.defaultPageSizeOptions,
      defaultPageSize: environment.defaultPageSize,
      USER_KEY: environment.USER_KEY,
      keycloakUrl: environment.keycloakUrl,
      keycloakRealm: environment.keycloakRealm,
      keycloakClientId: environment.keycloakClientId,
      defaultConfigurationVersion: environment.defaultConfigurationVersion,
      androidAppSoftwareId: environment.androidAppSoftwareId,
      translationFiles: [
        './assets/i18n/commons/',
        './assets/i18n/b2b/',
        './assets/i18n/workflow/',
        './assets/i18n/',
      ],
    }),
    WorkflowModule,
    JobModule,
    SoftwareModule,
    PagesModule,
    DeviceModule,
    QuillConfigModule.forRoot({
      modules: {
        syntax: { hljs },
        imageResizor: {},
        toolbar: defaultToolbar,
      },
    }),
    QuillModule.forRoot(),
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: (service: DiscoveryDocumentService) => () => service.load({} as DiscoveryDocument),
      deps: [DiscoveryDocumentService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (service: ProductFruitsService) => () => service.serviceInit(),
      deps: [ProductFruitsService],
      multi: true,
    },
    DatePipe,
    TimeagoPipe,
    {
      provide: NAV_ITEM_SERVICE,
      useClass: WorkflowNavItemService,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor() {
    registerLocaleData(de.default);
  }
}
