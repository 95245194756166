<div class="product-placement">
  <div class="center">
    <div
      id="carouselIndicators"
      class="carousel carousel-fade slide w-75 mx-auto"
      data-bs-ride="carousel"
    >
      <div class="carousel-inner text-center text-white">
        <div class="carousel-item active">
          <img
            src="./assets/img/product-placement/scanner.jpg"
            class="d-block w-100"
          />
          <div class="item-text">
            <h4 translate>product-placement.workflow-hand-title</h4>
            <p translate>product-placement.workflow-hand-text</p>
          </div>
        </div>
      </div>
      <!-- <div class="controls">
        <button
          class="carousel-control-prev"
          type="button"
          data-bs-target="#carouselIndicators"
          data-bs-slide="prev"
        >
          <span class="material-icons-outlined">arrow_back_ios</span>
          <span class="visually-hidden" translate>previous</span>
        </button>
        <div class="carousel-indicators position-static">
          <button
            type="button"
            data-bs-target="#carouselIndicators"
            data-bs-slide-to="0"
            class="active"
            aria-current="true"
            aria-label="Slide 1"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselIndicators"
            data-bs-slide-to="1"
            aria-label="Slide 2"
          ></button>
        </div>
        <button
          class="carousel-control-next"
          type="button"
          data-bs-target="#carouselIndicators"
          data-bs-slide="next"
        >
          <span class="material-icons-outlined">arrow_forward_ios</span>
          <span class="visually-hidden" translate>next</span>
        </button>
      </div> -->
    </div>
  </div>
</div>
