import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { AbstractControlOptions, FormBuilder, Validators } from '@angular/forms';
import { AuthService, CustomValidators } from '@nimmsta/ng-commons-lib';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent {
  public registerForm = this.fb.group(
    {
      organization: ['', [Validators.required, Validators.minLength(3)]],
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      password: ['', [Validators.required].concat(AuthService.pwdValidators)],
      password_repeat: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      email_repeat: ['', [Validators.required, Validators.email]],
    },
    {
      validators: [CustomValidators.sameEmail, CustomValidators.samePassword],
    } as AbstractControlOptions,
  );

  public isLoading = false;

  public error?: HttpErrorResponse;

  public isSubmitted = false;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
  ) {}

  onSubmit() {
    this.isLoading = true;
    this.error = undefined;
    const organization = this.registerForm.controls.organization.value!;
    const firstName = this.registerForm.controls.firstName.value!;
    const lastName = this.registerForm.controls.lastName.value!;
    const email = this.registerForm.controls.email.value!;
    const password = this.registerForm.controls.password.value!;
    this.authService.register(email, firstName, lastName, organization, password).subscribe({
      next: () => {
        this.isLoading = false;
        this.isSubmitted = true;
      },
      error: (error) => {
        this.isLoading = false;
        this.error = error;
      },
    });
  }

  resendVerification() {
    this.authService.resendVerification(this.registerForm.controls.email.value!).subscribe({
      error: (error: HttpErrorResponse) => {
        this.error = error;
      },
    });
  }
}
